// plugins.rdMailForm = $(".rd-mailform");
plugins.rdMailForm = null;

/**
 * isValidated
 * @description  check if all elemnts pass validation
 */
function isValidated(elements) {
  var results, errors = 0;
  if (elements.length) {
    for (j = 0; j < elements.length; j++) {

      var $input = $(elements[j]);

      if ((results = $input.regula('validate')).length) {
        for (k = 0; k < results.length; k++) {
          errors++;
          $input.siblings(".form-validation").text(results[k].message).parent().addClass("has-error");
        }
      } else {
        $input.siblings(".form-validation").text("").parent().removeClass("has-error")
      }
    }

    return errors == 0;
  }
  return true;
}

/**
 * RD Mailform
 */
if (plugins.rdMailForm && plugins.rdMailForm.length) {
  var i, j, k,
    msg = {
      'MF000': 'Successfully sent!',
      'MF001': 'Recipients are not set!',
      'MF002': 'Form will not work locally!',
      'MF003': 'Please, define email field in your form!',
      'MF004': 'Please, define type of your form!',
      'MF254': 'Something went wrong with PHPMailer!',
      'MF255': 'Aw, snap! Something went wrong.'
    };

  for (i = 0; i < plugins.rdMailForm.length; i++) {
    var $form = $(plugins.rdMailForm[i]);

    $form.attr('novalidate', 'novalidate').ajaxForm({
      data: {
        "form-type": $form.attr("data-form-type") || "contact",
        "counter": i
      },
      beforeSubmit: function () {
        var form = $(plugins.rdMailForm[this.extraData.counter]);
        var inputs = form.find("[data-constraints]");
        if (isValidated(inputs)) {
          var output = $("#" + form.attr("data-form-output"));

          if (output.hasClass("snackbars")) {
            output.html('<p><span class="icon text-middle fa fa-circle-o-notch fa-spin icon-xxs"></span><span>Sending</span></p>');
            output.addClass("active");
          }
        } else {
          return false;
        }
      },
      error: function (result) {
        var output = $("#" + $(plugins.rdMailForm[this.extraData.counter]).attr("data-form-output"));
        output.text(msg[result]);
      },
      success: function (result) {
        var form = $(plugins.rdMailForm[this.extraData.counter]),
          output = $("#" + form.attr("data-form-output")),
          $select = form.find('select');

        // Clear select2 after submit form
        if ($select.length) {
          for (j = 0; j < $select.length; j++) {
            var $selectitem = $($select[j]);
            $selectitem.select2('val', null);
          }
        }

        form.addClass('success');
        result = result.length == 5 ? result : 'MF255';
        output.text(msg[result]);

        if (result === "MF000") {
          if (output.hasClass("snackbars")) {
            output.html('<p><span class="icon text-middle fa-check icon-xxs"></span><span>' + msg[result] + '</span></p>');
          }
          output.addClass("success active");
        } else {
          if (output.hasClass("snackbars")) {
            output.html(' <p class="snackbars-left"><span class="icon icon-xxs fa-exclamation-triangle text-middle"></span><span>' + msg[result] + '</span></p>');
          }
          output.addClass("error active");
        }

        form.clearForm();
        form.find('input, textarea').blur();

        setTimeout(function () {
          output.removeClass("active error success");
          form.removeClass('success');
        }, 5000);
      }
    });
  }
}