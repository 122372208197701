plugins.rdParallax = $(".rd-parallax");

/**
 * RD Parallax
 * @description Enables RD Parallax plugin
 */
if (plugins.rdParallax && plugins.rdParallax.length) {
  var i;

  $.RDParallax();

  if (!isIE && !isMobile) {
    $(window).on("scroll", function () {
      for (i = 0; i < plugins.rdParallax.length; i++) {
        var parallax = $(plugins.rdParallax[i]);
        if (isScrolledIntoView(parallax)) {
          parallax.find(".rd-parallax-inner").css("position", "fixed");
        } else {
          parallax.find(".rd-parallax-inner").css("position", "absolute");
        }
      }
    });
  }

  $("a[href='#'], a[href^='#']").on("click", function (event) {
    setTimeout(function () {
      $(window).trigger("resize");
    }, 300);
  });
}

if (plugins.customParallax && plugins.customParallax.length) {
  for (var k = 0; k < plugins.customParallax.length; k++) {
    var $this = $(plugins.customParallax[k]),
      wrapper = $('.custom-parallax-wrap'),
      parallax = true,
      speed;

    if (parallax && !isIE && !isMobile) {
      if (speed = $this.attr("data-speed")) {
        makeParallax($this, speed, wrapper, false);
      }
    }
  }
}