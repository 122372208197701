plugins.rdNavbar = $(".rd-navbar");

/**
 * RD Navbar
 * @description Enables RD Navbar plugin
 */
if (plugins.rdNavbar && plugins.rdNavbar.length) {
  plugins.rdNavbar.RDNavbar({
    stickUpClone: (plugins.rdNavbar.attr("data-stick-up-clone")) ? plugins.rdNavbar.attr("data-stick-up-clone") === 'true' : false,
    responsive: {
      0: {
        stickUp: plugins.rdNavbar.attr("data-stick-up") === 'true'
      },
      768: {
        stickUp: plugins.rdNavbar.attr("data-sm-stick-up") === 'true'
      },
      992: {
        stickUp: plugins.rdNavbar.attr("data-md-stick-up") === 'true'
      },
      1200: {
        stickUp: plugins.rdNavbar.attr("data-lg-stick-up") === 'true'
      }
    },
    callbacks: {
      onStuck: function () {
        var navbarSearch = this.$element.find('.rd-search input');

        if (navbarSearch) {
          navbarSearch.val('').trigger('propertychange');
        }
      },
      onUnstuck: function () {
        if (this.$clone === null)
          return;

        var navbarSearch = this.$clone.find('.rd-search input');

        if (navbarSearch) {
          navbarSearch.val('').trigger('propertychange');
          navbarSearch.blur();
        }
      }
    }
  });
  if (plugins.rdNavbar.attr("data-body-class")) {
    document.body.className += ' ' + plugins.rdNavbar.attr("data-body-class");
  }
}
