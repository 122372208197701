plugins.swiper = $(".swiper-slider");

/**
 * getSwiperHeight
 * @description  calculate the height of swiper slider basing on data attr
 */
function getSwiperHeight(object, attr) {
  var val = object.attr("data-" + attr),
    dim;

  if (!val) {
    return undefined;
  }

  dim = val.match(/(px)|(%)|(vh)$/i);

  if (dim.length) {
    switch (dim[0]) {
      case "px":
        return parseFloat(val);
      case "vh":
        return $(window).height() * (parseFloat(val) / 100);
      case "%":
        return object.width() * (parseFloat(val) / 100);
    }
  } else {
    return undefined;
  }
}

/**
 * toggleSwiperInnerVideos
 * @description  toggle swiper videos on active slides
 */
function toggleSwiperInnerVideos(swiper) {
  var prevSlide = $(swiper.slides[swiper.previousIndex]),
    nextSlide = $(swiper.slides[swiper.activeIndex]),
    videos;

  prevSlide.find("video").each(function () {
    this.pause();
  });

  videos = nextSlide.find("video");
  if (videos.length) {
    videos.get(0).play();
  }
}

/**
 * toggleSwiperCaptionAnimation
 * @description  toggle swiper animations on active slides
 */
function toggleSwiperCaptionAnimation(swiper) {
  var prevSlide = $(swiper.container),
    nextSlide = $(swiper.slides[swiper.activeIndex]);

  prevSlide
    .find("[data-caption-animate]")
    .each(function () {
      var $this = $(this);
      $this
        .removeClass("animated")
        .removeClass($this.attr("data-caption-animate"))
        .addClass("not-animated");
    });

  nextSlide
    .find("[data-caption-animate]")
    .each(function () {
      var $this = $(this),
        delay = $this.attr("data-caption-delay");


      setTimeout(function () {
        $this
          .removeClass("not-animated")
          .addClass($this.attr("data-caption-animate"))
          .addClass("animated");
      }, delay ? parseInt(delay) : 0);
    });
}

/**
 * initSwiperCustomParallax
 * @description  toggle swiper parallax on active slides
 */
function initSwiperCustomParallax(swiper) {
  var prevSlide = $(swiper.container),
    nextSlide = $(swiper.slides[swiper.activeIndex]);

  prevSlide
    .find(".custom-parallax")
    .each(function () {
      var $this = $(this)
        wrapper = $('.custom-parallax-wrap'),
        parallax = true,
        speed;

      if (parallax && !isIE && !isMobile) {
        if (speed = $this.attr("data-speed")) {
          makeParallax($this, speed, wrapper, false);
        }
      }
    });

  nextSlide
    .find(".custom-parallax")
    .each(function () {
      var $this = $(this)
      wrapper = $('.custom-parallax-wrap'),
        parallax = true,
        speed;

      if (parallax && !isIE && !isMobile) {
        if (speed = $this.attr("data-speed")) {
          makeParallax($this, speed, wrapper, false);
        }
      }
    });
}

/**
 * initSwiperWaypoints
 * @description  toggle waypoints on active slides
 */
function initSwiperWaypoints(swiper) {
  var prevSlide = $(swiper.container),
    nextSlide = $(swiper.slides[swiper.activeIndex]);

  prevSlide
    .find('[data-custom-scroll-to]')
    .each(function () {
      var $this = $(this);
      makeWaypointScroll($this);
    });

  nextSlide
    .find('[data-custom-scroll-to]')
    .each(function () {
      var $this = $(this);
      makeWaypointScroll($this);
    });
}

/**
 * Swiper 3.1.7
 * @description  Enable Swiper Slider
 */
if (plugins.swiper && plugins.swiper.length) {
  var i;
  for (i = 0; i < plugins.swiper.length; i++) {
    var s = $(plugins.swiper[i]);
    var pag = s.find(".swiper-pagination"),
      next = s.find(".swiper-button-next"),
      prev = s.find(".swiper-button-prev"),
      bar = s.find(".swiper-scrollbar"),
      parallax = s.parents('.rd-parallax').length,
      swiperSlide = s.find(".swiper-slide"),
      autoplay = false;

    for (j = 0; j < swiperSlide.length; j++) {
      var $this = $(swiperSlide[j]),
        url;

      if (url = $this.attr("data-slide-bg")) {
        $this.css({
          "background-image": "url(" + url + ")",
          "background-size": "cover"
        })
      }
    }


    swiperSlide.end()
      .find("[data-caption-animate]")
      .addClass("not-animated")
      .end()
      .swiper({
        autoplay: s.attr('data-autoplay') ? (s.attr('data-autoplay') === "false" ? undefined : s.attr('data-autoplay')) : 5000,
        direction: s.attr('data-direction') ? s.attr('data-direction') : "horizontal",
        effect: s.attr('data-slide-effect') ? s.attr('data-slide-effect') : "slide",
        speed: s.attr('data-slide-speed') ? s.attr('data-slide-speed') : 600,
        keyboardControl: s.attr('data-keyboard') === "true",
        mousewheelControl: s.attr('data-mousewheel') === "true",
        mousewheelReleaseOnEdges: s.attr('data-mousewheel-release') === "true",
        nextButton: next.length ? next.get(0) : null,
        prevButton: prev.length ? prev.get(0) : null,
        pagination: pag.length ? pag.get(0) : null,
        paginationClickable: pag.length ? pag.attr("data-clickable") !== "false" : false,
        paginationBulletRender: pag.length ? pag.attr("data-index-bullet") === "true" ? function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + '</span>';
        } : null : null,
        scrollbar: bar.length ? bar.get(0) : null,
        scrollbarDraggable: bar.length ? bar.attr("data-draggable") !== "false" : true,
        scrollbarHide: bar.length ? bar.attr("data-draggable") === "false" : false,
        loop: s.attr('data-loop') !== "false",
        simulateTouch: s.attr('data-simulate-touch') ? s.attr('data-simulate-touch') === "true" : false,
        onTransitionStart: function (swiper) {
          toggleSwiperInnerVideos(swiper);
        },
        onTransitionEnd: function (swiper) {
          toggleSwiperCaptionAnimation(swiper);
        },
        onInit: function (swiper) {
          toggleSwiperInnerVideos(swiper);
          toggleSwiperCaptionAnimation(swiper);
          initSwiperCustomParallax(swiper);
          initSwiperWaypoints(swiper);
          $(window).on('resize', function () {
            swiper.update(true);
          })
        }
      });

    $(window)
      .on("resize", function () {
        var mh = getSwiperHeight(s, "min-height"),
          h = getSwiperHeight(s, "height");
        if (h) {
          s.css("height", mh ? mh > h ? mh : h : h);
        }
      })
      .trigger("resize");
  }
}